import axios from 'axios';

const tioTotoApiFile = axios.create({
    baseURL: "https://api.impresionestiototo.com.ar",
    //baseURL:"http://127.0.0.1:8080"
});


tioTotoApiFile.interceptors.request.use(async (config) => {
    config.headers['Content-Type'] = 'multipart/form-data';
    return config
});

tioTotoApiFile.interceptors.response.use(function (response) {
    return response;

}, function (error) {

    if (error.code === "ERR_CANCELED") {
        return Promise.resolve({ status: 499 })
    }

    return Promise.reject((error.response && error.response.data) || error)

});

export default tioTotoApiFile;
import axios from 'axios';


const tioTotoApi = axios.create({
    baseURL: "https://api.impresionestiototo.com.ar",
    //baseURL:"http://127.0.0.1:8080"
});

tioTotoApi.interceptors.request.use(async (config) => {
    config.headers['Content-Type'] = 'application/json';
    return config
});


tioTotoApi.interceptors.response.use(function (response) {

    return response;

}, function (error) {

    if (error.code === "ERR_CANCELED") {
        return Promise.resolve({ status: 499 })
    }

    return Promise.reject((error.response && error.response.data) || error)

});


export default tioTotoApi;
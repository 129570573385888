<template>
        <section class="section-head">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8 text-center">
                        <!-- <img class="pb-3 img-fluid-mio img-fluid px-xl-4 mt-xxl-n5" width="400" src="img/logoBlanco.png" /> -->
                        <h1 class="texto-titulo mb-1">Centro de Impresiones<br> T&iacute;o
                            Toto</h1>
                        <p class="texto-subtitlo">Envianos tu archivo para imprimir en unos
                            simples pasos</p>
                    </div>
                </div>
            </div>
        </section>
        <div class="row">
            <section class="section-items-step">
                <div class="container-fluid container-head pt-3 pb-2">
                    <div class="row justify-content-center section-items-step-container" style="border:1px solid rgba(0,0,0,.125)">
                            <div class="col-xs-12 col-md-3 d-flex justify-content-center">
                                <div class="section-facturacion-item d-flex justify-content-center gap-3
                                        pb-3 pb-md-0 w-100" style="color: #259dc7 !important;">
                                    <div class="section-facturacion-item__icon">
                                        <i class="fa-solid fa-print icon-alto"></i>
                                    </div>
                                    <div class="section-facturacion-item__text text-icons">
                                        <h6><b>Impresión</b></h6>
                                        Selecciona la opción de impresión que más prefieras.
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 d-flex justify-content-center">
                                <div class="section-facturacion-item d-flex justify-content-center gap-3
                                        pb-3 pb-md-0 w-100" style="color: #f1973b !important;">
                                    <div class="section-facturacion-item__icon">
                                        <i class="fa-solid fa-upload icon-alto"></i>
                                    </div>
                                    <div class="section-facturacion-item__text text-icons">
                                        <h6><b>Subí tu
                                                archivo</b></h6> Formatos de impresión válidos .doc .docx .ppt .pdf.
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 d-flex justify-content-center">
                                <div class="section-facturacion-item d-flex justify-content-center gap-3
                                        pb-3 pb-md-0 w-100" style="color: #95c123 !important;">
                                    <div class="section-facturacion-item__icon">
                                        <i class="fa-solid fa-wallet icon-alto"></i>
                                        <!-- <img id="logo" class="img-navbar" style="height:56px" src="img/logoMp.png"	onclick="redirect('index.html')"> -->
                                    </div>
                                    <div class="section-facturacion-item__text text-icons">
                                        <h6><b>Pagá
                                                online</b></h6> Aboná con Mercado Pago para confirmar tu pedido.
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 d-flex justify-content-center">
                                <div class="section-facturacion-item d-flex justify-content-center gap-3
                                        pb-3 pb-md-0 w-100" style="color: #b5b2b3 !important;">
                                    <div class="section-facturacion-item__icon">
                                        <i class="fa-solid fa-paper-plane icon-alto"></i>
                                    </div>
                                    <div class="section-facturacion-item__text text-icons">
                                        <h6><b>Retirá</b></h6>
                                        Te avisaremos por email cuando tu pedido este finalizado (<b>Tiempo estimado 24hs</b>)
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </section>
        </div>
</template>
  
<script>
export default {
    name: 'Section',
    props: {
        msg: String
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>
  
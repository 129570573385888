import tioTotoApi from '../api/tioToto.api'
import tioTotoApiFile from '../api/tioToto.api.file'

const tioTotoService = {
    getHojas() {
        return tioTotoApi.get(`/api/getTamHoja`);
    },
    getAtributosImpresion(code){
        return tioTotoApi.get(`/api/atributosImpresion/` + code);
    },
    getAtributosImpresionByCalidad(hoja,calidad){
        return tioTotoApi.get(`/api/atributosImpresionByCalidad/` + hoja + `/` +calidad);
    },
    getCotizacionByFile(formData){
        return tioTotoApiFile.post(`/api/cotizacionByFile`, formData);
    },
    postFile(formData){
        return tioTotoApiFile.post(`/api/uploadFile`, formData);
    },
    uploadCotizacionByUser(formData){
        return tioTotoApiFile.post(`/api/cotizacionByUser`, formData);
    },
    sendOrder(data){
        return tioTotoApi.post(`/api/generarPedido`,data);
    },
    sendNotification(data){
        return tioTotoApi.post(`/api/enviarPedido`,data);
    },
    getTerminadoAgrupado(data){
        return tioTotoApi.post(`/api/terminadoAgrupado`,data);
    },
    removeFileCotizacion(data){
        return tioTotoApi.put(`/api/removeFileByNameKey`,data);
    },
    listPrice(){
        return tioTotoApi.get(`/api/getListaPrecios`);
    },
    frequentQuestion(){
        return tioTotoApi.get(`/api/getPreguntasFrecuentes`);
    },
    userOrder(data){
        return tioTotoApi.get(`/api/buscarPedidos/`+data);
    }
}

export default tioTotoService
